@import "~bootstrap/dist/css/bootstrap.min.css";
/* You can add global styles to this file, and also import other style files */
.container {
  padding: 10px;
}

h2.page-title,
h2.content-block,
div > h2.content-block {
  font-size: 1.6rem;
  font-weight: bold;
  margin-left: 0;
  margin-bottom: 0;
  line-height: 1;
}

hr {
  margin-top: 0.1rem;
}

.screen-large .responsive-paddings {
  padding: 20px;
  padding-left: 40px;
  padding-right: 40px;
}

dx-button[type="default"].dx-buton.dx-button-default {
  background-color: #ee9900;
}

dx-button[type="success"].dx-button.dx-button-success {
  background-color: #04c42a;
}

dx-button[type="danger"].dx-button.dx-button-danger {
  background-color: red;
}

.online {
  color: #04c42a !important;
}

.offline {
  color: red !important;
}

.unknow {
  color: darkcyan !important;
}

.keep-fail {
  color: darkorange !important;
}

.red-border-class {
  border: 1px solid red;
}

.badges {
  > .dx-badge {
    padding: 5px 9px;
    border: 0.12rem solid;
    flex-shrink: 1;
    display: flex;
    margin: unset;
    font-weight: bold;
    cursor: help;
    color: #000000;
    background-color: #ffffff;

    &:not(:first-child) {
      margin-left: 0.2em;
    }
  }
}

@media (max-width: 768px) {
  .dx-popup-normal {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    max-height: 100%;

    .form-group.row {
      label {
        padding-left: 0;
      }
    }
  }
}

// .dx-popup-content {
//   height: 100% !important;
// }

.dx-toast-message {
  white-space: pre-line;
}

.dx-card.wide-card,
.dx-datagrid.dx-gridbase-container {
  max-height: 100% !important;
  height: 100% !important;
}

.dx-data-grid {
  width: 100% !important;
  max-width: 100% !important;
  max-height: 100% !important;
  height: 100% !important;
}

span {
  .dx-field-item-label-text {
    font-weight: bold;
  }

  .dx-field-item-required-mark {
    color: red;
  }
}

.dx-popup-normal {
  height: auto !important;
  max-height: calc(100vh - 100px);

  .dx-popup-content:not(.logging > .dx-popup-normal > .dx-popup-content) {
    height: auto !important;
  }

  .dx-scrollable-container {
    padding: 0.5em;
  }

  .dx-dialog-buttons {
    padding: unset;
    padding: 1em;
  }

  .form-group.row {
    margin-bottom: 0.5em;
    flex-shrink: 1;
    width: fit-content;
    margin-right: unset;
    margin-left: unset;
    width: 100%;

    label:first-child {
      padding-left: 0;
    }

    // label:not(:first-child) {
    //   text-align: end;
    // }

    label {
      font-weight: bold;
      padding-right: 0.375rem;
    }

    span {
      max-width: 100%;
      max-height: 80px;
      overflow: hidden;
    }
  }
}

.logging > .dx-popup-normal {
  color: #ffffff;
  // background-color: #252525;
  background-color: #000000;
  height: calc(100vh - 100px) !important;
  min-height: calc(100vh - 100px) !important;

  div,
  i {
    color: #ffffff;
  }
}

li.dx-treeview-node.dx-treeview-item-without-checkbox.dx-treeview-node-is-leaf:last-child {
  // position: fixed;
  bottom: 0px;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes from-left {
  from {
    opacity: 0;
    z-index: 0;
    margin-left: 0;
  }
  to {
    opacity: 1;
    z-index: 1000;
    margin-left: calc(30% - 29);
  }
}

@keyframes take-left {
  from {
    width: 0;
  }
  to {
    width: 30%;
  }
}

.btn-iris-primary,
.bd-iris-primary {
  color: #fff;
  font-weight: bold;
  background: rgb(255, 155, 2);
  background: linear-gradient(
    90deg,
    rgba(255, 155, 2, 1) 0%,
    rgba(238, 153, 0, 1) 50%,
    rgba(238, 183, 0, 1) 100%
  );
}
